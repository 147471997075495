import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { CatalogData, Category } from '@wix/bookings-uou-types';
import {
  BOOKINGS_FES_BASE_DOMAIN,
  BOOKINGS_WIDGET_BASE_DOMAIN,
} from '../consts';
import { WidgetData } from '../../legacy/types';
import { isAnywhereFlow } from '../utils/anywhere/anywhere';
import { getResourceQueryParam } from '../utils/queryParams/queryParams';

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
export const REVISION_HEADER_NAME = 'x-wix-site-revision';

export type ServiceListCatalogData = CatalogData & {
  categories: Category[];
};

export class BookingsApi {
  private wixSdkAdapter: WixOOISDKAdapter;
  private readonly flowAPI: ControllerFlowAPI;
  private serverBaseUrl: string;

  constructor({
    flowAPI,
    wixSdkAdapter,
  }: {
    flowAPI: ControllerFlowAPI;
    wixSdkAdapter: WixOOISDKAdapter;
  }) {
    this.flowAPI = flowAPI;
    this.wixSdkAdapter = wixSdkAdapter;
    this.serverBaseUrl = wixSdkAdapter.getServerBaseUrl();
  }

  async notifyOwnerNonPremiumEnrollmentAttempt() {
    return this.flowAPI.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/classes/nonPremium`,
      '',
      {
        headers: {
          [REVISION_HEADER_NAME]: this.wixSdkAdapter.getSiteRevision(),
          [XSRF_HEADER_NAME]: this.wixSdkAdapter.getCsrfToken(),
        },
      },
    );
  }

  async notifyOwnerNonPricingPlanEnrollmentAttempt(data: object) {
    return this.flowAPI.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/pricing-plans/invalidSetup`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          [REVISION_HEADER_NAME]: this.wixSdkAdapter.getSiteRevision(),
          [XSRF_HEADER_NAME]: this.wixSdkAdapter.getCsrfToken(),
        },
      },
    );
  }

  async getWidgetData(): Promise<WidgetData> {
    const {
      environment: { isEditor, isSSR, isViewer, isPreview },
      httpClient,
      controllerConfig: { config, wixCodeApi },
      experiments,
    } = this.flowAPI;

    const isAnywhereInd = await isAnywhereFlow(
      wixCodeApi,
      experiments,
      isPreview,
    );
    const publicDataOverrideURL = isAnywhereInd
      ? this.wixSdkAdapter.getUrlQueryParamValue(
          BookingsQueryParams.PUBLIC_DATA_OVERRIDES,
        )
      : undefined;
    const isLiveSiteNoSSR = isViewer && !isSSR;
    const metaSiteId = isLiveSiteNoSSR
      ? this.wixSdkAdapter.getMetaSiteId()
      : '';
    const serverUrl = `${this.serverBaseUrl}${BOOKINGS_WIDGET_BASE_DOMAIN}`;
    const { data } = await httpClient.get<WidgetData>(serverUrl, {
      params: {
        viewMode: isEditor ? 'editor' : 'site',
        cacheId: metaSiteId,
        externalId: config.externalId,
        ...getResourceQueryParam(this.wixSdkAdapter),
        publicDataOverrides: publicDataOverrideURL,
      },
    });
    return data;
  }
}
